<template>
    <ORowContainer>
        <ODataGrid
            ref="grid"
            :data="dataArray"
            hide-multiselect-column
            noRowsFound
            hideGridMenu
        >
            <template v-if="gridJson.fields" v-for="col in gridJson.fields">
                <TableLayoutColumnGroup v-if="col.groupId" :column="col"/>
                <OColumn v-else 
                    :colId="col.field" 
                    :format="col.format" 
                    :headerName="col.headerName ? col.headerName : col.field" 
                    :class="{'text-end': col.type == 'Number'}" 
                    :width="col.width"
                    :type="getColumnType(col.type)"
                    :summaryAggregate="col.aggregateType"
                >
                    <template #default="{ row, column }" v-if="col.url">
                        <OLink :href="row[column.url]" target="_blank">{{ row[column.field] }}</OLink>
                    </template>
                </OColumn>
            </template>
        </ODataGrid>
    </ORowContainer>
</template>

<script setup lang="ts">
    import { ref, Ref, watch } from 'vue';
    import { utils } from 'o365-utils';
    import { getOrCreateProcedure } from 'o365-modules';
    import { getOrCreateDataObject } from 'o365-dataobject';
    import TableLayoutColumnGroup from "status.vue.components.TableLayoutColumnGroup.vue";

    const props = defineProps({
        layoutId: {
            type: String,
            default: null,
            required: false
        },
        urlParams: {
            type: String,
            default: null,
            required: false
        },
        procedureParams: {
            type: Object,
            default: null,
            required: false
        },
        config: {
            type: String,
            default: null,
            required: false
        },
        procedure: {
            type: String,
            default: null,
            required: false
        },
    });

    const gridJson = ref({fields:[]});
    const dataArray = ref([]);
    const procParams:Ref<Object|null> = ref(null);
    const ID:Ref<string> = ref("");

    ID.value = props.layoutId;
    procParams.value = getProcedureParams();
    
    const dsLayout = getOrCreateDataObject({
        id: 'dsLayout'+crypto.randomUUID(),
        viewName: 'sviw_O365_TableLayouts',
        maxRecords: 1,
        whereClause: "Name = '" + ID.value + "'",
        selectFirstRowOnLoad: true,
        fields: [
            { name: "ID", type: "number" },
            { name: "Layout", type: "string" },
            { name: "StoredProcedure", type: "string" },
            { name: "ParametersForPreviewData", type: "string" },
        ]
    });
    
    var loadDataProc:any = null;

    function load(){
        if(props.procedure && props.config){
            loadDataProc = getOrCreateProcedure({ id: props.procedure+crypto.randomUUID(), procedureName: props.procedure });
            gridJson.value = JSON.parse(props.config);
            
            loadDataProc.execute(procParams.value).then((response)=>{
                dataArray.value = response.Table;
            });
        }else if(ID.value){
            dsLayout.load().then(async ()=>{
                if(dsLayout.current){

                    loadDataProc = getOrCreateProcedure({ id: dsLayout.current.StoredProcedure+crypto.randomUUID(), procedureName: dsLayout.current.StoredProcedure });
                    gridJson.value = JSON.parse(dsLayout.current.Layout??"{}");

                    loadDataProc.execute(procParams.value).then((response)=>{
                        dataArray.value = response.Table;
                    });
                }
            });
        }
    }
    load();

    function getColumnType(type:string){
        if(type == "Number"){
            return "number"
        }else if(type == "Date"){
            return "date"
        }else{
            return "string"
        }
    }

    function getProcedureParams() {
        if (props.procedureParams) {
            let cleanedUpObj = {};
            Object.entries(props.procedureParams).forEach(([key, value]) => {
                if (value) {
                    cleanedUpObj[key] = value;
                } else {
                    cleanedUpObj[key] = null;
                }
            });
            return cleanedUpObj
        } else if (props.urlParams) {
            let procParams = {};
            props.urlParams.split("&").forEach((param) => {
                let temp = param.split("=");
                if (temp[0] == "name"){  // to not pass the chart name as param
                    ID.value = temp[1];
                }else{
                    procParams[temp[0]] = temp[1];
                }
            });
            return procParams;
        } else {
            return {}
        }
    }

    watch(() => props.procedureParams, () => {
        procParams.value = getProcedureParams();
        load();
    }, { deep: true });
</script>